export const constantsLocale = () => {
  return {
    veevalidaion_messages: {
      required: '{field} field is required',
      email: '{field} field must be a valid email',
      min: '{field} field must be at least {length} characters',
      max: '{field} field may not be greater than {length} characters',
      numeric: '{field} field may only contain numeric characters',
      confirmed: '{field} field confirmation does not match',
      url: '{field} must be valid URL',
      ip_or_domain: '{field} must be valid IP or Domain',
      ip_or_host: '{field} must be valid IP or Domain or Host',
      ip: '{field} must be valid IP',
      domain: '{field} must be valid domain',
      short_text: 'Size of {field} must be less than {length}.',
      date_greater_than: '{field} must be greater than {date}.',
      date_less_than: '{field} must be less than {date}.',
      password: "{field} doesn't match the required criterias.",
      max_value: '{field} must be {value} or less',
      min_value: '{field} must be {value} or more',
      array_min_length: '{field} must be {value} or more',
      array_max_length: '{field} must be {value} or less',
      contact_number: '{field} must be valid',
      max_hour: '{field} must be {value} or less',
      port: '{field} must be {value} or less',
      mac_address: '{field} must be valid',
      ipV6: '{field} must be valid',
      system_oid: '{field} must be valid',
      trim: '{field} may not be contain spaces in beginning or ending',
      string_without_space: '{field} must not contain spaces',
      ip_range: 'IP Range must be valid',
      duration_days:
        'Duration Days must be valid. For ex. 0.25, 0.50, 0.75, 1.0, 1.25 etc.',
      duration_time: 'Duration gap should be more than 2 hours',
    },
    computer_property_group: 'Computer Properties',
    ram_property_group: 'RAM Properties',
    firewall_property_group: 'Firewall Properties',
    hardware_property_group: 'Hardware Properties',
    non_it_property_group: 'NonIT Property Group',
    mobile_device_property_group: 'Mobile Device Properties',
    rack_property_group: 'Rack Properties',
    snmp_property_group: 'SNMP Properties',
    vnc: 'VNC',
    host: 'Host',
    remotely: 'Remotely',
    rdp: 'RDP',
    snmp_device: 'SNMP Device',
    used: 'Used',
    request_preference: 'Request Preference',
    storage_property_group: 'Storage Properties',
    ups_property_group: 'UPS Properties',
    software_property_group: 'Software Properties',
    bios_component: 'BIOS',
    computer_system_component: 'Computer System',
    keyboard_component: 'Keyboard',
    logical_disk_component: 'Logical Disk',
    monitor_component: 'Monitor',
    motherboard_component: 'Motherboard',
    network_adapter_component: 'Network Adapter',
    os_component: 'OS',
    physical_disk_component: 'Physical Disk',
    pointing_device_component: 'Pointing Device',
    shared_folder_component: 'Shared Folder',
    usb_hub_component: 'USB Hub',
    usb_controller_component: 'USB Component',
    block_file_attachment_type: 'Block File Attachment Type',
    processor_component: 'Processor',
    cloud_property_group: 'Cloud Property Group',
    vm_instance_property_group: 'VM Instance Property Group',
    cloud_instance_details_component: 'Cloud Instance Details Component',
    database_property_group: 'Database Property Group',
    ram_component: 'RAM',
    software_component: 'Software',
    user_component: 'User',
    snmp_interface_component: 'SNMP Interface',
    mobile_device_system_component: 'System Component',
    mobile_device_os_component: 'OS Component',
    mobile_device_network_component: 'Network Component',
    mobile_device_sim_component: 'SIM Component',
    mobile_device_ram_component: 'RAM Component',
    mobile_device_external_memory_component: 'External Memory Component',
    mobile_application_component: 'Application',
    AD_note: 'This feature is supported only for AD integration over SSL/TLS',
    answer_questions: 'Please answer the following questions',
    header_reset_password: 'To Reset Password',
    header_unlock_account: 'To Unlock Account',
    expand: 'Expand',
    collapse: 'Collapse',
    unlock_account: 'Unlock Account',
    email: 'Email | Emails',
    username: 'Username',
    password: 'Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    name: 'Name',
    login: 'Login',
    sso_login: 'SSO Login',
    reset_password: 'Reset Password',
    set_password: 'Set Password',
    change_password: 'Change Password',
    chat: 'Chat',
    signup: 'Signup',
    code: 'Code',
    launch_survey: 'Launch Survey',
    size: 'Size',
    title: 'Title',
    first_name: 'First Name',
    last_name: 'Last Name',
    company_name: 'Company Name',
    confirm_password: 'Confirm Password',
    task: 'Task | Tasks',
    summary: 'Summary',
    task_is_done: 'this task is done',
    request: 'Request | Requests',
    page_layout: 'Page Layout',
    page_size: 'Page Size',
    items_per_page: 'Items Per Page',
    outgoing_call: 'Outgoing Call',
    page_size_instruction:
      'Selected Page Size will be applied as default page size',
    theme: 'Theme',
    dark_theme: 'Dark Theme',
    light_theme: 'Light Theme',
    incident: 'Incident | Incidents',
    service_request: 'Service Request',
    problem: 'Problem | Problems',
    change: 'Change | Changes',
    super_admin: 'Super Admin',
    archive_current_super_admin: 'Archive Current Super Admin',
    release: 'Release | Releases',
    asset: 'Asset | Assets',
    dashboard: 'Dashboard | Dashboards',
    profile: 'Profile',
    assets: 'Assets',
    my_assets: 'My Assets',
    if_condition_is: 'If Condition is',
    stop: 'Stop',
    edit_profile: 'Edit @:profile',
    are_you_sure: 'Are you sure',
    confirm: '@:are_you_sure, {message}?',
    confirm_answer: 'Confirm Answer',
    answer: 'Answer',
    1: '1',
    2: '2',
    answer_donot_match: 'Answer and Confirm Answer for {resource} do not match',
    confirm_mark_as_spam:
      '@:are_you_sure, you want to mark {resource} as spam?',
    confirm_mark_for_notification:
      '@:are_you_sure, you want to mark {resource} for Notification?',
    confirm_mark_as_prohibited:
      '@:are_you_sure, you want to mark {resource} as Prohibited Software?',
    confirm_generate_barcode:
      '@:are_you_sure, you want to Generate Barcode for selected {resource}?',
    confirm_generate_qrcode:
      '@:are_you_sure, you want to Generate QR code for selected {resource}?',
    confirm_mark_as_managed:
      '@:are_you_sure, you want to mark {resource} as Managed Software?',
    confirm_unmark_as_spam:
      '@:are_you_sure, you want to unmark {resource} as spam?',
    confirm_aks_feedback: '@:are_you_sure, you want to @:ask_for_feedback?',
    confirm_move_to_stage:
      '@:are_you_sure, you want to Move selected {resource} to Stage?',
    confirm_exclude_from_scan:
      '@:are_you_sure, you want to exclude from scan {resource}?',
    confirm_include_in_scan:
      '@:are_you_sure, you want to include in scan {resource}?',
    confirm_scan_now: '@:are_you_sure, you want to scan {resource}?',
    confirm_stop: '@:are_you_sure, you want to stop {resource}?',
    confirm_start: '@:are_you_sure, you want to start {resource}?',
    confirm_archive: '@:are_you_sure, you want to @:archive {resource}?',
    confirm_delete: '@:are_you_sure, you want to @:delete {resource}?',
    confirm_variance_detection:
      '@:are_you_sure, you want to detect {resource}?',
    confirm_approve: '@:are_you_sure, you want to approve {resource}?',
    confirm_decline: '@:are_you_sure, you want to decline {resource}?',
    confirm_patch_scan: '@:are_you_sure, you want to perform patch scan?',
    confirm_download: '@:are_you_sure, you want to @:download {resource}?',
    confirm_remote_desktop:
      '@:are_you_sure, you want to @:remote_desktop {resource}?',
    confirm_import: 'Are you sure, you want to import users?',
    confirm_assign:
      '@:are_you_sure, you want to @:assign selected {resource} to {user}?',
    confirm_claim:
      '@:are_you_sure, you want to assign selected {resource} to your self?',
    confirm_restore: '@:are_you_sure, you want to @:restore {resource}?',
    confirm_resolve: '@:are_you_sure, you want to resolve {resource}?',
    confirm_close: '@:are_you_sure, you want to @:close {resource}?',
    confirm_submit: '@:are_you_sure, you want to @:submit {resource}?',
    confirm_cancel: '@:are_you_sure, you want to @:cancel {resource}?',
    confirm_complete: '@:are_you_sure, you want to @:complete {resource}?',
    confirm_logout: '@:are_you_sure, you want to logout?',
    confirm_unconsolidate_item:
      '@:are_you_sure, you want to unconsolidate {item}?',
    confirm_deallocate_item: '@:are_you_sure, you want to deallocate {item}?',
    confirm_remove_item: '@:are_you_sure, you want to remove {item}?',
    confirm_remove_folder:
      'This will delete all draft articles forever and move published articles to Trash folder. Do you want to continue ?',
    please_select_to_continue: 'Please select {resource} to continue.',
    ask_resolve_item: 'Do you want to resolve {item}?',
    ask_terminate_session:
      'Are you sure you want to terminate current user session?',
    terminate_session: 'Terminate Session',
    patch_management: 'Patch Management',
    confirm_start_planning: 'Are you sure you want to Start Planning?',
    confirm_start_implementation:
      'Are you sure you want to Start Implementation?',
    ask_newly_scanned_asset: 'Do you want to add newly scanned assets in CMDB?',
    ask_execute_installation: 'Do you want to execute Agent Installation',
    ask_execute_uninstallation: 'Do you want to execute Agent Uninstallation',
    confirm_restore_item: '@:are_you_sure, you want to restore {item}?',
    confirm_reset_item: '@:are_you_sure, you want to reset {item}?',
    confirm_launch_survey: 'Are you sure you want to Launch Survey?',
    confirm_delete_item: '@:are_you_sure, you want to delete {item}?',
    confirm_archive_item: '@:are_you_sure, you want to archive {item}?',
    confirm_block_item: '@:are_you_sure, you want to block {item}?',
    confirm_unblock_item: '@:are_you_sure, you want to unblock {item}?',
    confirm_remove_qualification:
      '@:are_you_sure, you want to remove saved search?',
    confirm_remove_dashboard: '@:are_you_sure, you want to remove dashboard?',
    confirm_reset_form:
      '@:are_you_sure, This will reset all the fields in data?',
    confirm_ignore: '@:are_you_sure, you want to ignore?',
    confirm_archive_technician:
      'All pending work items assigned to this {item} will be marked as Unassigned. Do you want to continue ?',
    confirm_convert_requester:
      'Are you sure you want to convert this Requester to Technician ?',
    confirm_remove_summary_task:
      'All tasks under summary task will be deleted also. Are you sure?',
    confirm_unlock: '@:are_you_sure, you want to unlock {item} ?',
    confirm_move_stage:
      'You will not be able to move to {stage} stage after this operation. Do you want to continue ?',
    confirm_archive_service_category:
      'This will remove all services in this category. Do you want to continue?',
    confirm_reconcile: '@:are_you_sure, you want to @:reconcile {resource}?',
    confirm_callback: '@:are_you_sure, you want to call?',
    confirm_transfercall: '@:are_you_sure, you want to transfer call?',
    reconcile: 'Reconcile',
    conclusion: 'Conclusion',
    logout: 'Sign Out',
    yes: 'Yes',
    every: 'Every',
    cancel: 'Cancel',
    complete: 'Complete',
    smartphone: 'Smartphone',
    tablet: 'Tablet',
    enable_disable_mobile_discovery: 'Mobile Discovery',
    interval_time: 'Interval Time',
    english: 'English',
    gujarati: 'Gujarati',
    no: 'No',
    en: 'English',
    gu: 'Gujarati',
    view: 'View',
    unassigned: 'Unassigned',
    save: 'Save',
    install_as_user: 'Install As User',
    save_as: 'Save As',
    details: 'Details',
    detail: 'Detail',
    back: 'Back',
    previous: 'Previous',
    next: 'Next',
    view_archived: 'View Archived {resource}',
    split_view: 'Split View',
    calendar_view: 'Calendar View',
    list_view: 'List View',
    create: 'Create',
    submit: 'Submit',
    new: 'New',
    observation_start_time: 'Observation Start Time',
    remaining_approval_time: 'Remaining Approval Time',
    created_time: 'Created Date',
    updated_time: 'Updated Date',
    last_resolved_time: 'Resolved Date',
    last_closed_time: 'Closed Date',
    created_on: 'Created On',
    latest: 'Latest',
    suggested_kb: 'Suggested Knowledge',
    all_kb: 'All Knowledge',
    created_by: 'Created By',
    author: 'Author',
    auto_approve_after: 'Auto Approve After (Days)',
    updated_by: 'Updated By',
    subject: 'Subject',
    status: 'Status',
    allow_maximum: 'Allow Maximum',
    maximum_allowed_user: 'Maximum Allowed User',
    to: 'To',
    condition: 'Condition | Conditions',
    action: 'Action | Actions',
    assigned_to: 'Assigned to',
    assignee: 'Assignee',
    priority: 'Priority',
    impact: 'Impact',
    due_by: 'Due By',
    due_by_status: 'Due By Status',
    search: 'Search',
    search_by_name: 'Search By Name',
    unable_to_connect_url: 'Unable to connect following URLs',
    type_to_search: 'Type to search',
    unable_to_connect_server: 'Unable to connect with server.',
    technician: 'Technician | Technicians',
    first_response_date: 'First Response Date',
    notify_before_days: 'Notify Before(Days)',
    notify_after_days: 'Notify After(Days)',
    notify_reminder_days: 'Notify Reminder(Days)',
    send: 'Send',
    sent: 'Sent',
    ask: 'Aks',
    feedback: 'Feedback',
    enable_sms: 'Enable SMS',
    thank_you_feedback: 'Thank you for your valuable Feedback',
    thank_you_survey: 'Thank you for your feedback and comments',
    approved_successfully: 'Approval has been submitted Successfully.',
    link_expired: 'This link is expired or have already been used',
    email_notification: 'Email Notification | Email Notifications',
    sms_notification: 'SMS Notification | SMS Notifications',
    sms_gateway_configuration: 'SMS Gateway Configuration',
    event_notifications: 'Event Notifications',
    sms: 'SMS',
    email_template: 'Email Template',
    schedule_survey: 'Schedule Survey',
    send_email_to: 'Send Email To {receipent}',
    content: 'Content',
    no_chart_data: 'No Chart Data Found',
    no_data: 'No Data Found',
    error: 'Error',
    loading_error: 'Unable to load, Please try again',
    add: 'Add',
    added: 'Added',
    remove: 'Remove',
    delete: 'Delete',
    delegate: 'Delegate',
    delegated: 'Delegated',
    default: 'Default',
    same_value_exists: '{field} with the same name already exists',
    drag_to_change_order: 'Drag {field} to change its order',
    duplicate: 'Duplicate',
    edit: 'Edit',
    update: 'Update',
    manage_subscriber: 'Manage Subscribers',
    notify_cc_user: 'Notify CC User',
    subscriber: 'Subscriber',
    managed_by_user: 'Managed By User',
    managed_by_user_groups: 'Managed By Group Users',
    asset_group_owners: 'Asset Group Owner',
    asset_group_emails: 'Asset Group Emails',
    comment: 'Comment | Comments',
    delegation: 'Delegation',
    conversation: 'Conversation | Conversations',
    collaborate: 'Collaborate',
    collaboration: 'Collaboration | Collaborations',
    unanswer_bot_response: 'Unanswered Bot Response',
    unanswered: 'Unanswered',
    requester_communication: 'Requester Communication',
    forward_notification: 'Forward',
    forward: 'Forward',
    technician_communication: 'Technician Communication',
    reply_to_technician: 'Reply to Technician',
    mandatory_software: 'Mandatory Software',
    reply_from_requester: 'Reply from Requester',
    reply_from_technician: 'Reply from Technician',
    custom: 'Custom',
    custom_fields: '@:custom Fields',
    price_fields: '@:price Fields',
    technician_group: 'Technician Group',
    requester_group: 'Requester Group',
    category: 'Category | Categories',
    consider_note_as_first_response: 'Consider Note As First Response',
    template: 'Template | Templates',
    ip_address_restriction: 'IP Address Restriction',
    option: 'Option | Options',
    select: 'Select',
    select_one: '@:select One',
    upload: 'Upload',
    save_as_draft: 'Save as Draft',
    update_draft: 'Update Draft',
    department: 'Department | Departments',
    urgency: 'Urgency',
    location: 'Location | Locations',
    tag: 'Tag | Tags',
    existing: 'Existing',
    execution_complete_time: 'Execution Complete Time',
    append_to_existing_tags: 'Append Tags to Existing Tags',
    email_plural: 'Email | Emails',
    cc: 'Cc',
    enabled: 'Enabled',
    enable: 'Enable',
    send_message_unicode: 'Send Message in Unicode',
    disabled: 'Disabled',
    confirm_close_related:
      'Closing this {resource} will close all related {resources}. Do you want to continue ?',
    file_uploaded: '{fileName} has been uploaded successfully.',
    converted_successfully: '{resource} has been converted successfully.',
    started_successfully: '{resource} has been started successfully.',
    updated_successfully: '{resource} has been updated successfully.',
    linked_successfully: '{resource} has been linked successfully.',
    created_successfully: '{resource} has been created successfully.',
    changed_successfully: '{resource} has been changed successfully.',
    renewd_successfully: '{resource} has been renewed successfully.',
    added_successfully: '{resource} has been added successfully.',
    saved_successfully: '{resource} has been saved successfully.',
    sent_successfully: '{resource} has been sent successfully.',
    consolidate_successfully:
      'Software Asset have been consolidated successfully.',
    mark_for_notification_successfully:
      '{resource} has been marked for notification successfully.',
    generated_successfully: '{resource} has been generated successfully.',
    resource_will_be_sent:
      '{resource} will be sent on the provided Email addresses.',
    sent_error: 'Failed to send {resource}.',
    executed_successfully: '{resource} has been executed successfully.',
    deleted_successfully: '{resource} has been deleted successfully.',
    export_successfully:
      '{resource} has been exported successfully. You will receive email shortly.',
    archived_successfully: '{resource} has been archived successfully.',
    restored_successfully: '{resource} has been restored successfully.',
    removed_successfully: '{resource} has been removed successfully.',
    status_changed_successfully: '{resource} have been {status} successfully.',
    updated_successfully_multiple: '{resource} have been updated successfully.',
    created_successfully_multiple: '{resource} have been created successfully.',
    added_successfully_multiple: '{resource} have been added successfully.',
    saved_successfully_multiple: '{resource} have been saved successfully.',
    deleted_successfully_multiple: '{resource} have been deleted successfully.',
    archived_successfully_multiple:
      '{resource} have been archived successfully.',
    removed_successfully_multiple: '{resource} have been removed successfully.',
    configured_successfully: '{resource} have been configured successfully.',
    reset_successfully: '{resource} have been reset successfully.',
    reconcile_successfully: '{resource} have been reconciled successfully.',
    scan_submitted_successfully: 'Scan submitted successfully.',
    bulk_update_error: '{resource} bulk operation failed because of error.',
    validate_audience_count:
      'Minimum audience count {minSize} is required. Current audience count is {count}',
    requester: 'Requester | Requesters',
    description: 'Description',
    other_info: 'Other Info',
    for: 'For',
    last_updated: 'Last Updated',
    last_updated_date: 'Last Updated Date',
    last_discovered_time: 'Last Discovered Date',
    first_response_due_by: 'First Response Due By',
    application: 'Application | Applications',
    level: 'Level | Levels',
    support_level: 'Support Level',
    current_sla: 'Current SLA',
    tier: 'Tier',
    source: 'Source',
    select_status: 'Select Status',
    email_command_settings: 'Email Command Settings',
    language: 'Language',
    language_preference: 'Language Preference',
    email_command: 'Email Command',
    escalation_level: 'Escalation Level',
    not_violated: 'Not Violated',
    ola: 'OLA',
    operational_level_agreement: 'Operational Level Agreement (OLA)',
    violated: 'Violated',
    violate: 'Violate',
    escalation_level_value: 'Level {level}',
    closed: 'Closed',
    resolved: 'Resolved',
    resolution: 'Resolution',
    time: '{type} Time',
    wait_time: 'Wait Time',
    talk_time: 'Talk Time',
    hold_time: 'Hold Time',
    last: 'Last',
    attempt: 'Attempt | Attempts',
    called: 'Called',
    callback_reminder: 'Callback Reminder',
    team_activity_summary: 'Team Activity Summary',
    coops_recent_calls: 'Recent Calls',
    coops_waiting_calls: 'Waiting Calls',
    coops_callback_reminder: 'Callback Reminders',
    coops_onhold_calls: 'On Hold Calls',
    coops_active_agents: 'Active Agents',
    coops_abandoned_calls: 'Abandoned Calls',
    processing_time: 'Processing Time',
    connection_type: 'Connection Type',
    time_taken: 'Time Taken',
    cc_emails: 'Cc Emails',
    cc_email: 'Cc Email | Cc Emails',
    estimated_time: 'Estimated Time',
    hours: 'Hour | Hours',
    days: 'Day | Days',
    months: 'Month | Months',
    years: 'Year | Years',
    minutes: 'Minute | Minutes',
    seconds: 'Second | Seconds',
    item: 'Item | Items',
    total: 'Total',
    pagination_info: 'Showing {from}-{to} of {total} {item}',
    headings: 'Headings',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    paragraph: 'Paragraph',
    heading: 'Heading',
    bullet_list: 'Bullet List',
    ordered_list: 'Numbered List',
    hr: 'Separator line',
    undo: 'Undo',
    redo: 'Redo',
    done: 'Done',
    apply: 'Apply',
    work: 'Work',
    attach: 'Attach',
    deleted: 'Deleted',
    attachment: 'Attachment | Attachments',
    file: 'File | Files',
    first: 'First',
    response: 'Response',
    canned: 'Canned',
    poor: 'Poor',
    fair: 'Fair',
    average: 'Average',
    good: 'Good',
    excellent: 'Excellent',
    insert: 'Insert',
    placeholder: 'Placeholder | Placeholders',
    from: 'From',
    ip: 'IP',
    knowledge: 'Knowledge',
    schedule_survey_form: 'Schedule Survey Form',
    start: 'Start',
    end: 'End',
    date: 'Date',
    type: 'Type',
    microsoft_ad: 'Microsoft AD',
    open_ldap: 'Open LDAP',
    start_typing: '@:start Typing',
    reply: 'Reply',
    reply_requester: 'Reply To Requester',
    diagnosis: 'Diagnosis',
    solution: 'Solution',
    note: 'Note | Notes',
    text: 'Text',
    load_more: 'Load More',
    all: 'All',
    reopen: 'Reopen',
    reopened: 'Reopened',
    overdue: 'Overdue',
    overdue_by: 'Overdue by',
    work_log: 'Work Log',
    audit_trail: 'Audit Trail',
    check_list: 'Check List',
    history: 'History',
    activity: 'Activity | Activities',
    activate: 'Activate',
    id: 'ID',
    less_than: 'Less than',
    before: 'Before',
    after: 'After',
    less_than_or_equal: '@:less_than or Equal',
    greater_than: 'Greater than',
    greater_than_or_equal: '@:greater_than or Equal',
    equal: 'Equals',
    between: 'Between',
    equal_case_insensitive: '@:equal',
    like: 'Like',
    start_with: 'Start With',
    end_with: 'End With',
    contains: 'Contains',
    not_contains: 'Not Contains',
    any: 'Any',
    none: 'None',
    is_member: 'Is Member',
    all_members_exist: 'Match All',
    any_member_or_all_members_exist: 'Match Any',
    no_members_exist: 'Not Exist',
    not_any_members_exist: 'Not Any Member Exist',
    any_member_but_not_all_members_exist:
      'Any Member but not all Members exist',
    is_due_within: 'Due within',
    due_in: 'Due In',
    has_passed: 'Has Passed',
    match_tree: 'Match Tree',
    not_equal: 'Not Equals',
    not_equal_case_insensitive: '@:not_equal',
    not_in: 'Not In',
    not_in_case_insensitive: 'Not In',
    in: 'In',
    in_case_insensitive: 'In',
    operator: 'Operator',
    value: 'Value',
    top: 'Top',
    values: 'Values',
    preference: 'Preference | Preferences',
    spam: 'Spam',
    prohibited: 'Prohibited',
    managed: 'Managed',
    mark_as_prohibited: 'Mark as @:prohibited Software',
    mark_as_managed: 'Mark as @:managed Software',
    mark_for_notification: 'Mark for Notification',
    mark_as_spam: 'Mark as @:spam',
    unmark_as_spam: 'Unmark as @:spam',
    watcher: 'Watcher',
    watch: 'Watch',
    unwatch: 'Unwatch',
    print: 'Print',
    module: 'Module',
    information: 'Information',
    additional_info: 'Additional Information',
    automation: 'Automation',
    workflow: 'Workflow',
    slack: 'Slack',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    sla: 'SLA',
    approval: 'Approval | Approvals',
    notification: 'Notification | Notifications',
    copied: 'Copied',
    auto_assignment: 'Auto Assignment',
    auto_refresh_interval: 'Auto Refresh Interval',
    integration: 'Integration | Integrations',
    scenario: 'Scenario',
    user: 'User | Users',
    group: 'Group | Groups',
    selected_applications: 'Selected Applications',
    exported: 'Exported',
    my_group: 'My Group',
    my_request: 'My Requests',
    role: 'Role | Roles',
    snmp_property_device: 'SNMP Device',
    prefrences: 'Preferences',
    organization: 'Organization',
    account: 'Account',
    business: 'Business',
    branding: 'Branding',
    break: 'Break',
    brand: 'Brand',
    helpdesk: 'Helpdesk',
    it_infrastructure: 'IT Infrastructure',
    network: 'Network | Networks',
    credentials: 'Credentials',
    credential: 'Credential',
    network_scan: 'Network Scan',
    agent_network_scan: 'Agent Network Scan',
    domain_scan: 'Domain Scan',
    sccm_scan: 'SCCM Scan',
    dns: 'DNS',
    configuration: 'Configuration | Configurations',
    server: 'Server',
    ldap: 'LDAP',
    sso: 'SSO',
    azure_ad: 'Azure Ad',
    one_login: 'One Login',
    okta: 'OKTA',
    proxy: 'Proxy',
    plugin: 'Plugin | Plugins',
    and: 'And',
    or: 'Or',
    management: 'Management',
    form: 'Form',
    admin: 'Admin',
    description_instruction: 'Description...',
    duration: 'Duration',
    only_time: 'Time',
    expired_on: 'Expired On',
    expiring_on: 'Expiring On',
    access_level: 'Access Level',
    sub_category: 'Sub Category',
    take: 'Take',
    close: 'Close',
    assign: 'Assign',
    archive: 'Archive',
    remote_desktop: 'Remote Desktop',
    service_pack: 'Service Pack',
    move_to_stage: 'Move To Stage',
    archived: 'Archived',
    merge: 'Merge',
    merged: 'Merged',
    unselect: 'Unselect',
    by: 'By',
    at: 'at',
    more: 'More',
    less: 'Less',
    read: 'Read',
    set_as: 'Set as',
    color: 'Color',
    run: 'Run',
    running: 'Running',
    notify_before_hour: 'Notify Before Hour | Notify Before Hours',
    notify_before: 'Notify Before',
    storage_bucket_component: 'Storage Bucket Component',
    cloud_database_component: 'Cloud Database Component',
    folder_name: 'Folder Name',
    access_key_id: 'Access Key Id',
    secret_key_id: 'Secret Key Id',
    subscription_id: 'Subscription Id',
    cloud_network: 'Cloud Network',
    aws_database: 'AWS Database',
    cloud_type: 'Cloud Type',
    aws_ec2: 'EC2',
    aws_s3: 'S3',
    aws_rds: 'RDS',
    aws_credential: 'AWS Credential',
    azure_database: 'Azure Database',
    azure_storage: 'Azure Storage',
    azure_vm: 'VM',
    discovered: 'Discovered',
    aws: 'AWS',
    azure: 'AZURE',
    schedular: 'Schedular',
    auto_record_remote_session: 'Auto Record Remote Session',
    execute: 'Execute',
    claim: 'Claim',
    primary: 'Primary',
    secondary: 'Secondary',
    make: 'Make',
    relation: 'Relation | Relations',
    create_and_relate: 'Create And Relate',
    linked: 'Linked',
    link: 'Link',
    unlink: 'Unlink',
    sub: 'Sub',
    timezone: 'Time Zone',
    do_not_disturb: 'Do not Disturb',
    logon_name: 'Logon Name',
    under_change_control: 'Under Change Control',
    variance_detection: 'Variance Detection',
    variance_detected: 'Variance Detected',
    email_or_logon: 'Email or Logon Name',
    authentication_source: 'Authentication Source',
    blocked: 'Blocked',
    block: 'Block',
    block_number: 'Block Number',
    unblocked: 'Unblocked',
    show_history: 'Show History',
    ldap_history: 'LDAP History',
    local: 'Local',
    active: 'Active',
    in_active: 'In Active',
    associate_parent_entity: 'Associate Parent Entity',
    no_user_assigned: 'No User Assigned',
    no_technician_assigned: 'No Technician Assigned',
    scope: 'Scope',
    select_image: 'Select Image',
    max_attachment_count_violation: 'Max Attachment Count Violation',
    max_attachment_count_violation_discription:
      'Maximum {count} attachments are allowed at a time',
    unsupported_file_type: 'Selected File type is not supported',
    unsupported_file_size: 'Selected file size is too large',
    only_supported_file_size: 'Please upload file up to {fileSize} only.',
    only_supported_file_types: 'Only {fileTypes} are supported',
    blocked_file_attachments: 'Blocked File Attachments : {blockedExtension}',
    priority_matrix: 'Priority Matrix',
    setting: 'Setting | Settings',
    feedback_setting: 'Feedback Setting',
    patch_settings: 'Patch Settings',
    access_controls: 'Access Controls',
    patch_computers: 'Patch Computers',
    remote_office: 'Remote Office | Remote Offices',
    deployment_policy: 'Deployment Policy | Deployment Policies',
    agent_nomination: 'Agent Nomination',
    nomination_category: 'Nomination Category',
    total_installation: 'Total Installation | Total Installations',
    install_after: 'Install After',
    expiry_date: 'Expiry Date',
    decline_patch: 'Decline Patch | Decline Patches',
    patch_approval_policy: 'Patch Approval Policy',
    not_visible_to_requester: 'Not Visible to Requester',
    times: 'Time | Times',
    requester_will_be_created:
      'This requester is not available in system and will be created upon creation of this request',
    visibility: 'Visibility',
    my_self: 'My Self',
    self_service_settings: 'Self Service Settings',
    ad_self_service: 'AD Self Service',
    my_tasks: 'My Tasks',
    reference: 'Reference',
    my_approvals: 'My Approvals',
    my_changes: 'My Changes',
    blocked_number: 'Number blocked successfully',
    me: 'Me',
    ago: 'ago',
    last_1_hours: 'Last 1 Hour',
    last_2_hours: 'Last 2 Hours',
    last_4_hours: 'Last 4 Hours',
    last_8_hours: 'Last 8 Hours',
    last_24_hours: 'Last 24 Hours',
    today: 'Today',
    tomorrow: 'Tomorrow',
    this_week: 'This Week',
    this_month: 'This Month',
    last_week: 'Last Week',
    last_month: 'Last Month',
    recent: 'Recent',
    restore: 'Restore',
    vip: 'VIP',
    jira_id: 'Jira ID',
    project: 'Project | Projects',
    member: 'Member',
    issue_type: 'Issue Type',
    response_template: 'Response Template | Response Templates',
    unanimous: 'Unanimous',
    majority: 'Majority',
    anyone: 'Any One',
    ask_for_approval: 'Ask for Approval',
    ask_for_feedback: 'Ask for Feedback',
    set_decision_to: 'Set Decision To',
    decision_type: 'Decision Type',
    approver: 'Approver | Approvers',
    approve: 'Approve',
    as_highly_vulnerable: 'Criteria for tagging system as "Highly Vulnerable"',
    as_vulnerable: 'Criteria for tagging system as "Vulnerable"',
    system_health_settings: 'System Health Settings',
    reject: 'Reject',
    skip: 'Skip',
    pending: 'Pending',
    payment: 'Payment | Payments',
    received: 'Received',
    approved: 'Approved',
    rejected: 'Rejected',
    pre_approved: 'Pre Approved',
    endpoints: 'End Points',
    ignored: 'Ignored',
    not_requested: 'Not Requested',
    requested: 'Requested',
    progress: 'In Progress',
    service_catalog: 'Service Catalog',
    service: 'Service | Services',
    publish: 'Publish',
    published: 'Published',
    updated: 'Updated',
    draft: 'Draft',
    total_read: 'Total Read',
    elements: 'Elements',
    system_fields: 'System Fields',
    preview: 'Preview',
    form_placeholder_text:
      'No Element Found Please add some element from left sidebar',
    section: 'Section',
    datetime: 'Datetime',
    number: 'Number',
    text_input: 'Text Input',
    text_area: 'Text Area',
    dropdown: 'Dropdown',
    radio: 'Radio',
    question: 'Question | Questions',
    checkbox: 'Checkbox',
    label: 'Label',
    width: 'Width',
    hight: 'Hight',
    printer: 'Printer',
    no_of_labels_per_row: 'No. of labels per row',
    gap_between_labels_in_row: 'Gap between labels in a row',
    mm: '(mm)',
    dependent: 'Dependent',
    rating: 'Rating | Ratings',
    numeric_scale: 'Numeric Scale',
    boolean: 'Boolean',
    tags: 'Tags',
    field: 'Field | Fields',
    hint_text: 'Hint Text',
    behaviour: 'Behaviour',
    appearance: 'Appearance',
    input_width: 'Width of Input',
    required: 'Required',
    allow_half_star: 'Allow half star for rating',
    use_on_portal_field: 'Show on Requester form',
    system_managed_field: 'System Managed Field',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    default_value: 'Default Value',
    input_type: 'Input type',
    requester_name: 'Requester Name',
    rows: 'Rows',
    button_text: 'Button Text',
    browse_file: 'Browse File',
    fetch_data: 'Fetch Data',
    allow_decimal: 'Allow Decimal in number?',
    calculation_type: 'Calculation Type',
    absolute: 'Absolute',
    polarity: 'Polarity',
    subtract: 'Subtract',
    allow_time: 'Allow to select time?',
    applicable_on_shipment_charges: 'Applicable on Shipment Charges?',
    push_next_line: 'Push next field to new line?',
    section_field_note:
      '*This configurations will apply to all fields of this section',
    allow_leaf_node_only: 'Allow user to select leaf node only',
    allow_entire_hierarchy_report: 'Display Entire Hierarchy in Report',
    display_option_vertically: 'Display Option Vertically',
    simple: 'Simple',
    allow_virtual_agent_support: 'Allow Virtual Agent Support',
    greeting_flow: 'Greeting Flow',
    fallback_flow: 'Fallback Flow',
    goto_step: 'Go to Step',
    upper_case_key: {
      get: 'GET',
      post: 'POST',
      patch: 'PATCH',
      put: 'PUT',
      delete: 'DELETE',
      json: 'JSON',
    },
    url: 'URL',
    body: 'Body',
    http_parameters: 'Http Parameters',
    http_headers: 'Http Headers',
    secure_api: 'Secure @:api',
    test_connection: 'Test Connection',
    workflowModule: {
      action: 'Action',
      trigger: 'Trigger',
      trigger_is_required: 'Trigger is required.',
      action_is_required: 'Action is required',
      condition_is_required: 'Condition is required',
      action_is_required_for_condition: 'Action is required for the Condition',
      condition: 'Condition',
      positive_action: '@:workflowModule.action for Yes',
      negative_action: '@:workflowModule.action for No',
      workflow_type: '@:workflow Type',
      periodic: 'Periodic',
      event: 'Event',
      interval: 'Interval',
    },
    slaModule: {
      operational_hours_type: 'Operational Hours Type',
      calendar_hours: 'Calendar Hours',
      business_hours: 'Business Hours',
      max_response_time: 'Max Response Time',
      max_resolution_time: 'Max Resolution Time',
      escalation: 'Escalation',
      before: 'Before',
      after: 'After',
      response_time: 'Response Time',
      resolution_time: 'Resolution Time',
    },
    set_decision: 'Set Individual Decision Type',
    set_users: 'Set Approvers to',
    manager_head: 'Requester’s  Manager',
    assignee_manager: 'Assignee’s Manager',
    department_head: 'Department Head',
    stage: 'Stage | Stages',
    step: 'Step | Steps',
    ignore: 'Ignore',
    remind: 'Remind',
    approval_needed: 'Approval Needed',
    manual_created: 'Manually Created',
    automatic_created: 'Automation Created',
    reminder: 'Reminder',
    success: 'Success',
    since: 'since',
    copy: 'Copy',
    copy_of: 'Copy of',
    copy_of_id: 'Copy of ID',
    import: 'Import',
    export: 'Export',
    csv: 'CSV',
    batch: 'Batch | Batches',
    import_batch: 'Import Batch',
    row: 'Row | Rows',
    ignore_version: 'Ignore Version',
    import_csv: {
      click_here: 'Click here',
      sample_file: 'to download sample CSV file.',
      upload_instruction:
        'Please select a CSV file, make sure the selected file is in UTF-8 format, and the first row of file contains Field Names.',
      mapping_instruction:
        'Please select appropriate column to map against system field.',
      finalize_instruction: 'Please select records you want to import.',
      save_batch_instruction:
        'Records are successfully uploaded with batch reference: {batchNumber}.',
      map_fields: 'Map Fields',
      finalize: 'Finalize',
      save_batch: 'Save Batch',
      character_validation_csv_import:
        'Value beyond 100 characters will be truncated.',
    },
    folder: 'Folder | Folders',
    permission: 'Permission | Permissions',
    ip_network: 'IP Network',
    wmi_credential: 'WMI Credential',
    ssh_credential: 'SSH Credential',
    snmp_credential: 'SNMP Credential',
    vmware_credential: 'VMWARE Credential',
    read_permission: 'Read Permission',
    write_permission: 'Write Permission',
    public: 'Public',
    all_logged_in_users: 'All Logged-in Users',
    domain: 'Domain | Domains',
    keyword: 'Keyword | Keywords',
    filter: 'Filter | Filters',
    scheduled: 'Scheduled',
    partially_processed: 'Partially Processed',
    allow: 'Allow',
    support_channel: 'Support Channel | Support Channels',
    support_portal: 'Support Portal',
    active_directory_self_service: 'Active Directory Self Service',
    support_console: 'Support Console',
    data_indexing: 'Data Indexing',
    ldap_configurations: 'LDAP Configurations',
    sso_configuration: 'SSO Configuration',
    ldap_schedule: 'LDAP Schedule',
    support: 'Support',
    technician_portal: 'Technician Portal',
    count: 'Count',
    custom_rules: 'Custom Rules',
    note_required_for_changes: 'Note is required for this change',
    known_error: 'Known Error',
    nature_of_problem: 'Nature of Problem',
    proactive: 'Proactive',
    reactive: 'Reactive',
    analysis: 'Analysis',
    rootcause: 'Root Cause',
    symptomps: 'Symptoms',
    workaround: 'Work Around',
    identified_as_problem: 'Identified as Problem',
    logo: 'Logo',
    favicon: 'Favicon',
    currency: 'Currency',
    contact_no: 'Contact No.',
    image: 'Image',
    video: 'Video',
    table: 'Table',
    alt_contact_no1: 'Alternate Contact No. 1',
    alt_contact_no2: 'Alternate Contact No. 2',
    full_name: 'Full Name',
    column: 'Column | Columns',
    most_read: 'Most Read',
    suggested_knowledge: 'Suggested Knowledge',
    explore: 'Explore',
    report: 'Report | Reports',
    record: 'Record | Records',
    verified: 'Verified',
    verify: 'Verify',
    unverified: 'Unverified',
    reset_to_default: 'Reset to Default',
    asc: 'Ascending',
    desc: 'Descending',
    sort_by: 'Sort By',
    sort_order: 'Sort Order',
    schedule: 'Schedule',
    start_at: 'Start At',
    once: 'Once',
    ip_range_type: 'IP Range Type',
    credential_type: 'Credential Type',
    wmi: 'WMI',
    ssh: 'SSH',
    snmp: 'SNMP',
    snmp_v3: 'SNMP V3',
    vmware: 'VMWARE',
    entire_network: 'Entire Network',
    specific_range: 'Specific Range',
    specific_ip_range: 'Specific IP Range',
    snmp_property: 'SNMP Property',
    specific_set_of_ips: 'Specific Set Of IPs',
    snmp_field_type: 'SNMP Field Type',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    interval: 'Interval',
    excel: 'Excel',
    pdf: 'PDF',
    word: 'Word',
    format: 'Format',
    change_management: 'Change Management',
    change_risk: 'Change Risk',
    change_type: 'Change Type | Change Types',
    release_management: 'Release Management',
    purchase_management: 'Purchase Management',
    knowledge_management: 'Knowledge Management',
    gl_code: 'GL Code',
    patch: 'Patch | Patches',
    older: 'Older',
    global_access: 'Global Access',
    global_restricted: 'Global Restricted',
    group_access: 'Group Access',
    restricted: 'Restricted',
    release_risk: 'Release Risk',
    release_type: 'Release Type | Release Types',
    product_type: 'Product Type | Product Types',
    rdp_configuration: 'RDP Configuration | RDP Configurations',
    rdp_permission: 'RDP Permission',
    recorded_rdp_sessions: 'Recorded RDP Sessions',
    rdp_agent: 'RDP Agent',
    software_rule: 'Software Rule | Software Rules',
    software_type: 'Software Type | Software Types',
    snmp_custom_properties: 'SNMP Custom Properties',
    affected_products: 'Affected Products',
    product: 'Product | Products',
    software: 'Software',
    change_reason: 'Change Reason',
    release_reason: 'Release Reason',
    asset_group: 'Asset Group',
    manufacturer_catalog: 'Manufacturer Catalog',
    manufacturer: 'Manufacturer',
    reason: 'Reason',
    target_environment: 'Target Environment',
    submitted: 'Submitted',
    planning: 'Planning',
    implementation: 'Implementation',
    build: 'Build',
    testing: 'Testing',
    deployment: 'Deployment | Deployments',
    package: 'Package | Packages',
    review: 'Review',
    in_review: 'In Review',
    in_progress: 'In Progress',
    manage: 'Manage',
    ip_range_location: 'IP Range Location',
    open: 'Open',
    download: 'Download',
    rolloutplan: 'Rollout Plan',
    backoutplan: 'Backout Plan',
    buildplan: 'Build Plan',
    testplan: 'Test Plan',
    down_time: 'Down Time | Down Times',
    to_lower: 'to',
    implementer: 'Implementer',
    manager: 'Manager',
    engineer: 'Engineer',
    download_app: 'Download App',
    qa: 'QA',
    reviewer: 'Reviewer',
    affected_services: 'Affected Services',
    reportModule: {
      count: 'Count Of',
      avg: 'Average Of',
      top_count: 'Top Count',
      top_count_type: 'Top Count Type',
      selected_columns: 'Selected Columns',
      excluded_columns: 'Excluded Columns',
      selected_values: 'Selected Values',
      excluded_values: 'Excluded Values',
      deleted_values: 'Value Deleted',
      deleted_columns: 'Column Deleted',
      numberFunction: {
        sum: 'Sum',
        avg: 'Average',
        count: 'Count',
        min: 'Minimum Value',
        max: 'Maximum Value',
      },
    },
    refresh: 'Refresh',
    reload: 'Reload',
    validation_failed_message:
      'Form fields contain one or more errors, Please correct errors and try again.',
    expoert_data_validation_message:
      'Select at least one column to perform this operation',
    announcement: 'Announcement | Announcements',
    application_maintenance: 'Application Maintenance',
    security: 'Security',
    system_preference: 'System Preference',
    task_type: 'Task Type | Task Types',
    passwordPolicy: {
      min_length: 'Minimum {length} characters',
      special_characters: 'Must have a special character (~!@#$%^&*_-)',
      alphanumeric: 'Must have a number and an alphabet',
      mixcase: 'Must have a lower case and an upper case character',
      doesnotmatch: "Password doesn't match the required criteria",
    },
    go_to_home: 'Go To Home',
    character_count: 'Characters Count',
    oops: 'Oops',
    '404_message': 'Looks like you are lost',
    '500_message':
      'The Server encountered an internal error or misconfiguration and was unable to complete your request',
    no_asset_found_barcode: 'No Asset found with barcode {barcode}',
    no_asset_found_qrcode: 'No Asset found with QR Code',
    error_loading_message: 'Error While Fetching Data.',
    authorization_error:
      'You do not have permission to perform this operation.',
    sorry: 'Sorry',
    license_expired_contact_us:
      'Your {type} is expired. To activate license contact our sales team',
    license: 'License',
    security_question_answer: 'Security Questions',
    apply_on_every_start_up: 'Apply On Every Start Up',
    no_question_answer_configured: 'No security questions are configured.',
    configure_security_question_answer: 'Configure Security Questions?',
    trail: 'Trial',
    computer_list: 'Computer List',
    computer: 'Computer | Computers',
    sales_email: 'sales@motadata.com',
    login_screen_preference: 'Login Screen Preference',
    single_login_screen: 'Single Login Screen',
    separate_login_screen: 'Seperate Login Screen',
    sales_phone: '+91 9912345678',
    go_to: 'Navigate to {resource}',
    shortcut: 'Shortcut | Shortcuts',
    created_by_me: 'Created By me',
    shared_with_me: 'Shared With me',
    share: 'Share',
    notifications: 'Notifications',
    preference_settings: 'Preference Settings',
    ui_notifications: 'UI Notifications',
    approval_decision_assigned_items: 'Approval decisions on assigned items',
    approver_comments_on_assigned_items: 'Approver comments on assigned items',
    request_assigned_to_me: 'Request assigned to me',
    request_assigned_to_my_group: 'Request assigned to my group',
    comments_added_on_assigned_request: 'Comments added on assigned request',
    comments_mentioning_me_on_unassigned_request:
      'Comments mentioning me on unassigned request',
    notes_added_on_assigned_request: 'Notes added on assigned request',
    requester_reply_on_assigned_request: 'Requester reply on assigned request',
    status_update_on_assigned_request: 'Status update on assigned request',
    feedback_received_on_request: 'Feedback received on request',
    problem_assigned_to_me: 'Problem assigned to me',
    problem_assigned_to_my_group: 'Problem assigned to my group',
    change_assigned_to_me: 'Change assigned to me',
    change_assigned_to_my_group: 'Change assigned to my group',
    release_assigned_to_me: 'Release assigned to me',
    release_assigned_to_my_group: 'Release assigned to my group',
    asset_managed_by_me: 'Asset manageded by me',
    asset_managed_by_my_group: 'Asset manageded by my group',
    my_approval_is_requested: 'My approval is requested',
    my_approval_is_ignored: 'My approval is ignored',
    my_new_request_is_created: 'My new request is created',
    response_received_on_my_request: 'Response received on my request',
    resolution_of_my_request: 'Resolution of my request',
    barcode_and_qr_code: 'Barcode / QR Code',
    mark_as_read: 'Mark as Read',
    mark_as_unread: 'Mark as Unread',
    mark_all_as_read: 'Mark all as read',
    clear_all: 'Clear All',
    show_on_screen_notification: 'Show on screen Notification',
    notification_sent_success: 'Notification Sent Successfully',
    has_assigned: 'has assigned',
    to_you: 'to you',
    preference_setting: 'Preference Setting',
    shortcutHelpers: {
      keyboard_shortcuts: 'Keyboard Shortcuts',
      view_available: 'View Available Keyboard Shortcuts',
      navigation: 'Navigation',
      'grid-split-helpers': '{resource} Grid or Split View',
      'grid-helpers': '{resource} Grid or Split View',
      'detailed-view-helpers': '{resource} Detailed View',
      up: 'Move cursor to previous record',
      down: 'Move cursor to next record',
      space: 'Select current record',
      enter: 'View details of current record',
      'next-page': 'Go to next page',
      'next-ticket': 'Go to next Record',
      'previous-page': 'Go to previous page',
      'previous-ticket': 'Go to previous Record',
      'create-task': 'Create New Task',
      'create-worklog': 'Add Worklog',
      'close-ticket': 'Close current record',
      'mark-spam': 'Mark current Request as spam',
      archive: 'Archive current record',
      watch: 'Watch current Request',
      scenario: 'Run Scenario for current Request',
      reply: 'Reply to Request',
      collaborate: 'Collaborate',
      note: 'Add note',
      diagnosis: 'Add diagnosis',
      solution: 'Add solution',
      forward: 'Forward',
    },
    warranty_period: 'Warranty Period',
    copy_upc_code: 'Copy UPC Code',
    copy_barcode: 'Copy Barcode',
    prefix: 'Prefix',
    static_pages: 'Static Pages',
    split_request: 'Split Request',
    incoming_mail_box: 'Incoming Mailbox',
    search_bar_placeholder: 'Select field or enter keyword to search...',
    search_bar_placeholder_only_keywords: 'Enter keyword to search...',
    release_date: 'Release Date',
    asset_in_stage: 'Asset in Stage',
    true: 'True',
    false: 'False',
    warranty_expiration_date: 'Warranty Expiration Date',
    audit_date: 'Audit Date',
    consolidate: 'Consolidate',
    add_html_here: 'Add HTML here...',
    page_content: 'Page Content',
    consolidated: 'Consolidated',
    unconsolidate: 'Unconsolidate',
    add_to: 'Add to',
    cmdb: 'CMDB',
    asset_type: 'Asset Type | Asset Types',
    owner: 'Owner',
    total_cost: 'Total Cost',
    created_date: 'Created Date',
    last_barcode_qrcode_scan_date: 'Last Barcode / QR Code Scan Date',
    last_barcode_qrcode_scan_by: 'Last Barcode / QR Code Scan By',
    total_fetched_users: 'Total Fetched Users',
    total_created_users: 'Total Created Users',
    total_failed_users: 'Total Failed Users',
    total_deleted_users: 'Total Deleted Users',
    total_updated_users: 'Total Updated Users',
    detected_date: 'Detection Date',
    reference_rollback_request: 'Reference Rollback Request(Change)',
    ordered_date: 'Ordered Date',
    received_date: 'Received Date',
    order_number: 'Order Number',
    incident_scheduler: 'Incident Schedule | Incident Schedules',
    it: 'IT',
    blue_magenta: 'Blue Magenta',
    green: 'Green',
    red: 'Red',
    orange: 'Orange',
    select_theme: 'Select Theme',
    non_it: 'Non IT',
    component: 'Component',
    consumable: 'Consumable',
    others: 'Others',
    search_bar_placeholder_only_field: 'Select field to search...',
    search_keyword_validation_message:
      'Size of searched keyword must be less than 255.',
    property: 'Property | Properties',
    product_catalog: 'Product Catalog',
    vendor: 'Vendor | Vendors',
    barcodes: 'Barcodes',
    downloaded_success: 'Downloaded Successfully',
    vendor_catalog: 'Vendor Catalog',
    address: 'Address',
    street: 'Street',
    landmark: 'Landmark',
    city: 'City',
    pincode: 'Pincode',
    live: 'Live',
    ready_to_launch: 'Ready To Launch',
    design: 'Design',
    launched: 'Launched',
    total_requests: 'Total Requests',
    received_responses: 'Received Responses',
    pending_responses: 'Pending Responses',
    completion: 'Completion (%)',
    survey_start_date: 'Survey Start Date',
    survey_end_date: 'Survey End Date',
    closed_date: 'Closed Date',
    cancled_date: 'Cancelled Date',
    state: 'State',
    country: 'Country',
    price: 'Price',
    list: 'List',
    managed_by: 'Managed By',
    used_by: 'Used By',
    acquition: 'Acquisition',
    assignment: 'Assignment',
    barcode: 'Barcode',
    qrcode: 'QR Code',
    software_type_settings: 'Software Type Settings',
    hardware: 'Hardware',
    agent_information: 'Agent Information',
    asset_hardware: 'Hardware Asset | Hardware Assets',
    asset_software: 'Software Asset | Software Assets',
    asset_non_it: 'Non IT Asset | Non IT Assets',
    asset_consumable: 'Consumable Asset | Consumable Assets',
    system_oid: 'System OID',
    ip_address: 'IP Address',
    agent_id: 'Agent ID',
    platform: 'Platform',
    agent_activation_status: 'Agent Activation Status',
    os_name: 'OS Name',
    os_platform: 'OS Platform',
    agent_last_sync_date: 'Agent Last Sync Date',
    domain_name: 'Domain Name',
    mac_address: 'MAC Address',
    host_name: 'Host Name',
    version: 'Version',
    origin: 'Origin',
    generate: 'Generate',
    survey_form_notification_template: 'Survey Form Notification Template',
    scan: 'Scan',
    assignment_date: 'Assignment Date',
    acquisition_date: 'Acquisition Date',
    manual: 'Manual',
    discovery: 'Discovery',
    asset_discovery: 'Asset Discovery',
    manage_asset_discovery: 'Manage Asset Discovery',
    discovery_agent: 'Discovery Agent',
    discovery_cycle: 'Discovery Cycle',
    refresh_cycle: 'Refresh Cycle',
    scan_qr_for_mobile_discovery: 'Scan this QR code for mobile discovery',
    discovery_cycle_must_be_greater_than_refresh_cycle:
      'Note: Discovery Cycle must be greater than Refresh Cycle by 15 or more minutes',
    survey_preference: 'Survey Preference',
    project_preference: 'Project Preference',
    project_config: 'Project Configuration',
    config_type: 'Config Type',
    deploy_stage: 'Deploy Stage',
    notify_to: 'Notify to',
    retry_failed_configuration: 'Retry Failed Configuration',
    retry_count: 'Retry Count',
    result: 'Result',
    startup_retry_count: 'Start Up Retry Count',
    scan_cycle_retry_count: 'Scan Cycle Retry Count',
    minimum_questions_in_survey: 'Minimum Questions in a Survey',
    minimum_audience_size: 'Minimum Audience Size',
    agent: 'Agent',
    available_agents: 'Available Agents',
    api: 'API',
    command: 'Command',
    csvimport: 'CSV Import',
    api_capital: 'API',
    scheduler: 'Scheduler',
    elastic: 'Elastic',
    event: 'Event',
    repository: 'Repository',
    system: 'System',
    re_consile: 'Reconsile',
    csv_imported: 'CSV Import',
    sccm: 'SCCM',
    external_api: 'External API',
    with: 'With',
    virtual_machine: 'Virtual Machine',
    hyper_v_server: 'Hyper-V Server',
    cpu_allocation: 'CPU Allocation',
    memory_allocation: 'Memory Allocation',
    reservation: 'Reservation',
    shares: 'Shares',
    shares_level: 'Shares Level',
    vm_id: 'VM ID',
    unlimited: 'Unlimited',
    in_mhz: '(in MHz)',
    in_mb: '(in MB)',
    contact: 'Contact',
    phone_numbers: 'Phone Numbers',
    vm_name: 'VM Name',
    installation: 'Installation',
    windows: 'Windows',
    unix_ubuntu: 'Linux Ubuntu',
    unix_mint: 'Linux Mint',
    unix_red_hat: 'Linux Redhat',
    unix_open_suse: 'Linux Opensuse',
    unix_suse: 'Linux Suse',
    unix_centos: 'Linux Centos',
    mac: 'MAC',
    unix: 'Linux',
    android: 'Android',
    ios: 'IOS',
    linux: 'Linux',
    bit: 'Bit',
    kb: 'KB',
    gb: 'GB',
    mb: 'MB',
    hz: 'Hz',
    ghz: 'GHz',
    mhz: 'MHz',
    of: 'Of',
    inch: 'inch',
    cm: 'cm',

    asset_enum: {
      all: 'All',
      false: 'No',
      true: 'Yes',
      bit_64: '64 BIT',
      bit_32: '32 BIT',
      arm64: 'ARM64',
      unspecified: 'Unspecified',
      desktop: 'Desktop',
      mobile: 'Mobile',
      workstation: 'Workstation',
      enterprise_server: 'Enterprise Server',
      soho_server: 'SOHO Server',
      appliance_pc: 'Appliance PC',
      performance_server: 'Performance Server',
      maximum: 'Maximum',
      ok: 'Ok',
      error: 'Error',
      degraded: 'Degraded',
      unknown: 'Unknown',
      pred_fail: 'Pred Fail',
      starting: 'Starting',
      stopping: 'Stopping',
      service: 'Service',
      stressed: 'Stressed',
      non_recover: 'Non Recover',
      no_contact: 'Non Contact',
      lost_communication: 'Lost Communication',
      no_root_directory: 'No Root Directory',
      removable_disk: 'Removable Disk',
      local_disk: 'Local Disk',
      network_drive: 'Network Drive',
      compact_disc: 'Compact Disc',
      ram_disk: 'Ram Disk',
      mouse: 'Mouse',
      track_ball: 'Track Ball',
      rack_point: 'Rack Point',
      glide_point: 'Glide Point',
      touch_pad: 'Touch Pad',
      touch_screen: 'Touch Screen',
      mouse_optical_sensor: 'Mouse Optical Sensor',
      disconnected: 'Disconnected',
      connecting: 'Connecting',
      connected: 'Connected',
      disconnecting: 'Disconnecting',
      hardware_not_present: 'Hardware Not Present',
      hardware_disabled: 'Hardware Disabled',
      hardware_malfunctino: 'Hardware Malfunction',
      media_disconnected: 'Media Disconnected',
      authenticating: 'Authenticating',
      authentication_succeeded: 'Authentication Succeeded',
      authentication_failed: 'Authentication Failed',
      invalid_address: 'Invalid Address',
      licensed: 'Licensed',
      unlicensed: 'Unlicensed',
      undefined: 'Undefined',
      oobgrace: 'OOB Grace',
      ootgrace: 'OOT Grace',
      nongenuinegrace: 'Non Genuine Grace',
      notification: 'Notification',
      extendedgrace: 'Extended Grace',
      temporary_duplicate_account: 'Temporary Duplicate Account',
      normal_account: 'Normal Account',
      interdomain_trust_account: 'Interdomain Trust Account',
      workstation_trust_account: 'Workstation Trust Account',
      server_trust_account: 'Server Trust Account',
      other: 'Other',
      dram: 'DRAM',
      synchronous_dram: 'Synchronous DRAM',
      cache_dram: 'Cache DRAM',
      edo: 'EDO',
      edram: 'EDRAM',
      vram: 'VRAM',
      sram: 'SRAM',
      ram: 'RAM',
      rom: 'ROM',
      flash: 'FLASH',
      eeprom: 'EEPROM',
      feprom: 'FEPROM',
      eprom: 'EPROM',
      cdram: 'CDRAM',
      ram_3d: 'RAM 3D',
      sdram: 'SDRAM',
      sgram: 'SGRAM',
      rdram: 'RDRAM',
      ddr: 'DDR',
      ddr2: 'DDR2',
      ddr2_fb_dimm: 'DDR2 FB DIMM',
      ddr3: 'DDR3',
      fbd2: 'FBD2',
    },
    scan_history: 'Scan History',
    change_log: 'Change Log | Change Logs',
    first_scan_date: 'First Scan Date',
    last_scan_date: 'Last Scan Date',
    exclude_from_scan: 'Exclude From Scan',
    excluded_from_scan: 'Excluded From Scan',
    include_in_scan: 'Include In Scan',
    scan_now: 'Scan Now',
    dns_configuration: 'DNS Configuration',
    snmp_custom_property: 'SNMP Custom Properties',
    scalar: 'Scalar',
    tabular: 'Tabular',
    contract: 'Contract | Contracts',
    workstation: 'Workstation',
    desktop: 'Desktop',
    tenant_prefrences: 'Tenant Preferences',
    prohibited_software_rule:
      'Prohibited Software Rule | Prohibited Software Rules',
    scan_existing_software: 'Scan Existing Softwares',
    consfirm_scan_existing_software:
      'This will scan all existing Non-Prohibited Softwares, Do you want to Proceed?',
    prohibited_software_scanning_started:
      'Prohibited software scanning started successfully. This process will take some time.',
    computer_exclusion: 'Computer Exclusion',
    add_to_exclusion_list: 'Add to Exclusion List',
    excluded_computers: 'Excluded Computers',
    software_normalization_rule:
      'Software Normalization Rule | Software Normalization Rules',
    auto_uninstallation_policy: 'Auto Uninstallation Policy',
    rule_type: 'Rule Type',
    field_assignment: 'Field Assignment',
    software_suite: 'Software Suite',
    prohibited_softwares_allowed: 'Prohibited Softwares Allowed',
    include_in_next_run: 'Include Normalized Softwares in Next Run',
    run_now: 'Run Now',
    run_now_started:
      'Software scanning started successfully. This process will take some time.',
    onsfirm_run_now: 'Are you sure, you want to run software normalization?',
    job: 'Job',
    next_execution_time: 'Next Execution Time',
    last_execution_time: 'Last Execution Time',
    license_file: 'License File',
    user_preference: 'User Preference',
    asset_preference: 'Asset Preference',
    application_settings: 'Application Settings',
    not_started: 'Not Started',
    next_30_days: 'Next 30 Days',
    current_time: 'Current Time',
    last_30_days: 'Last 30 Days',
    expired: 'Expired',
    cancelled: 'Cancelled',
    start_date: 'Start Date',
    end_date: 'End Date',
    renew_date: 'Renew Date',
    renewal_date: 'Renewal Date',
    cost: 'Cost',
    parent: 'Parent',
    identifier: 'Identifier',
    mapping: 'Mapping | Mappings',
    input: 'Input',
    output: 'Output',
    key: 'Key',
    train_model: 'Train Model',
    training_data: 'Training data. Wait for 3-5 minutes',
    max_file_size: 'Max Upload Size (MB)',
    max_multi_file_upload: 'Max Attachment at a time',
    primary_contact_person: 'Contact Person Name',
    renewed: 'Renewed',
    renewal: 'Renewal | Renewals',
    renew: 'Renew',
    expiry: 'Expiry',
    child: 'Child',
    software_license: 'Software License | Software Licenses',
    license_type: 'License Type',
    purchase: 'Purchase | Purchases',
    order: 'Order | Orders',
    ordered: 'Ordered',
    cost_center: 'Cost Center',
    allocation: 'Allocation',
    co_related: 'Co-Related',
    deallocate: 'Deallocate',
    allocate: 'Allocate',
    invoice: 'Invoice | Invoices',
    compliance: 'Compliance',
    utilization: 'Utilization',
    over: 'Over',
    under: 'Under',
    limit: 'Limit',
    rdp_auth_fail: 'Asset authentication failed for RDP, contact administrator',
    recording: 'Recording | Recordings',
    relationship_type: 'Relationship Type | Relationship Types',
    direct_relationship_name: 'Direct Relationship Name',
    inverse_relationship_name: 'Inverse Relationship Name',
    contract_type: 'Contract Type | Contract Types',
    icon: 'Icon',
    depreciation: 'Depreciation',
    method: 'Method',
    configure: 'Configure',
    re_configure: 'Re Configure',
    straight_line: 'Straight Line',
    declining_balance: 'Declining Balance',
    sum_of_the_years_digit: 'Sum Of The Years Digit',
    double_declining_balance: 'Double Declining Balance',
    useful_life: 'Useful Life',
    percentage: 'Percentage',
    percent: 'Percent',
    salvage: 'Salvage',
    financial: 'Financial | Financials',
    operation: 'Operation',
    operational: 'Operational',
    disposal: 'Disposal',
    call_notes_and_disposal: 'Add Call Notes & Call Disposal',
    other: 'Other',
    current: 'Current',
    book: 'Book',
    amount: 'Amount',
    factor: 'Factor',
    annually: 'Annually',
    accumulated: 'Accumulated',
    remaining: 'Remaining',
    life: 'Life',
    derivation: 'Derivation',
    product_level: 'Product Level',
    asset_level: 'Asset Level',
    do_not_deprecate: 'Do Not Deprecate',
    user_security: 'User Security',
    agent_setting: 'Agent Setting',
    mobile_agent_setting: 'Mobile Agent Setting',
    asset_finance: 'Asset Finance',
    convert_to_m4v: 'Convert to M4V',
    converted: 'Converted',
    not_converted: 'Not Converted',
    failed: 'Failed',
    meter: 'Meter',
    last_sync_date: 'Last Sync Date',
    last_sync_time: 'Last Sync Time',
    usage_duration: 'Usage Duration',
    last_used: 'Last Used',
    os: 'OS',
    software_meter: 'Software Meter',
    reset: 'Reset',
    usage_count: 'Usage Count',
    info: 'Info',
    connection_error: 'Connection Error',
    is_typing: '{user} is typing',
    is_offline: '{user} is offline',
    ongoing: 'Ongoing',
    missed: 'Missed',
    completed: 'Completed',
    marked_as_failed: 'Marked As Failed',
    chat_completed: 'This chat has been marked as completed.',
    you_are_chatting: 'You are now chatting with {user}',
    new_chat: 'New Support Chat Request',
    new_chat_request: 'New Chat Request From Requester {user}',
    transfer: 'Transfer',
    conference: 'Conference',
    new_chat_transfer_request:
      '{technician} wants to transfer chat with {requester}',
    chat_transfer_rejected: '{technician} rejected chat transfer request',
    chat_transfer_timeout: 'Chat transfer request timeout',
    hide_from_purchase_fom: 'Hide from Purchase Order?',
    required_by: 'Required By',
    vendor_validation_message: 'Vendor is required to perform this operation',
    sr_no: 'Sr.No',
    tax_rate: 'Tax Rate',
    quantity: 'Quantity',
    part_number: 'Part Number',
    sub_total: 'Sub Total',
    discount: 'Discount',
    total_net: 'Total Net',
    shipping: 'Shipping',
    billing: 'Billing',
    charges: 'Charges',
    terms: 'Terms',
    place: 'Place',
    signing_authority: 'Signing Authority',
    po: 'PO',
    bill: 'Bill',
    due_date: 'Due Date',
    request_archive: 'Request Archive',
    receive: 'Receive',
    connection_success: 'Connected Successfully.',
    connection_succeeded: 'Connection was successfully established',
    connection_failure: 'Failed to connect.',
    connection_failed: 'Unable to connect with provided details.',
    operation_completed: 'The Operation has been completed',
    operation_fail: 'The Operation has been failed',
    include: 'Include',
    user_survey: 'User Survey | User Surveys',
    survey: 'Survey | Surveys',
    launch: 'Launch',
    launch_on: 'Launch On',
    launch_now: 'Launch Now',
    validity: 'Validity',
    audience: 'Audience',
    exclude: 'Exclude',
    rate_options: 'Rate Options',
    basic: 'Basic',
    minimum: 'Minimum',
    maximum: 'Maximum',
    additional: 'Additional',
    label_for_yes: 'Label for Yes',
    label_for_no: 'Label for No',
    node: 'Node',
    request_leaf_node: 'Allow Request Creation at Leaf Node Only',
    problem_leaf_node: 'Allow Problem Creation at Leaf Node Only',
    change_leaf_node: 'Allow Change Creation at Leaf Node Only',
    release_leaf_node: 'Allow Release Creation at Leaf Node Only',
    category_hierarchy_report: 'Display Category Hierarchy in Report ',
    hierarchy_report: 'Display {modulename} Hierarchy in Report',
    answered: 'Answered',
    notanswered: 'Notanswered',
    hardware_serial_number: 'Hardware - Serial Number',
    software_software_name: 'Software - Software Name',
    non_it_serial_number: 'Non IT - Serial Number',
    computer_os_name: 'Computer - OS Name',
    requester_chat: 'Requester Chat',
    transition: 'Transition',
    column_deleted: 'Column Deleted',
    logged_in: 'Logged In',
    logged_out: 'Logged Out',
    telephony: 'Telephony',
    channel: 'Channel',
    setup: 'Setup',
    contactops: 'ContactOps',
    self: 'Self',
    external: 'External',
    call: 'Call | Calls',
    calling: 'Calling',
    abandoned: 'Abandoned',
    time_spent_in_current_status: 'Time Spent in Current Status',
    callback: 'Callback',
    extension: 'Extension',
    reason_required_for_changes: 'Reason is required for this change',
    call_time: 'Call Time',
    then: 'then',
    mute: 'Mute',
    unmute: 'Unmute',
    hold: 'Hold',
    unhold: 'Unhold',
    remark: 'Remark | Remarks',
    project_management: 'Project Management',
    project_risk: 'Project Risk',
    project_type: 'Project Type | Project Types',
    project_owner: 'Project Owner',
    milestone: 'Milestone | Milestones',
    planned: 'Planned',
    predecessors: 'Predecessors',
    successors: 'Successors',
    gantt: 'Gantt',
    summary_task: 'Summary Task',
    estimates: 'Estimates',
    dependency: 'Dependency',
    worklog: 'Worklog',
    only: 'Only',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    wrap_up_time: 'Wrap Up Time',
    on_call: 'On Call',
    log: 'Log | Logs',
    dial: 'Dial',
    change_schedule_conflict: 'Change Schedule Conflict',
    full_screen_view: 'Full Screen View',
    change_schedule_conflict_message:
      'Change schedule conflict detected. Click here to view.',
    co_ops_call_history: 'ContactOps Call History',
    managed_computer: 'Managed Computer | Managed Computers',
    windows_patch: 'Windows Patch | Windows Patches',
    auto_patch_test: 'Automatic Patch Test | Automatic Patch Tests',
    bulletin_id: 'Bulletin Id',
    kb_number: 'KB Number',
    severity: 'Severity',
    missing: 'Missing',
    installed: 'Installed',
    superseded: 'Superseded',
    on: 'On',
    behalf: 'Behalf',
    delegate_start_message:
      'All your approvals are delegated to {user} from {start_date}',
    delegate_end_message: 'to {end_date}',
    reboot: 'Reboot',
    uninstallation: 'Uninstallation',
    test: 'Test | Tests',
    refrence: 'Reference',
    patch_storage_config: 'Patch Storage Configuration',
    architecture: 'Architecture',
    system_health: 'System Health',
    missing_patches: 'Missing Patches',
    installed_patches: 'Installed Patches',
    remote_deployment: 'Patch Deployment | Patch Deployments',
    patch_remote_deployment: 'Patch Deployment | Patch Deployments',
    packages_remote_deployment: 'Package Deployment | Package Deployments',
    registry_remote_deployment: 'Registry Deployment | Registry Deployments',
    automatic_patch_deployment:
      'Automatic Patch Deployment | Automatic Patch Deployments',
    communication_type: 'Communication Type',
    distribution_server_ip: 'Distribution Server IP',
    distribution_server_url: 'Distribution Server URL',
    direct_communication: 'Direct Communication',
    distribution_server: 'Distribution Server',
    port: 'Port',
    rule: 'Rule | Rules',
    target: 'Target',
    applied: 'Applied',
    deploy: 'Deploy',
    policy: 'Policy',
    install: 'Install',
    uninstall: 'Uninstall',
    retry: 'Retry',
    decline: 'Decline',
    may_be: 'May be',
    copy_data_from_parent_request: 'Copy data from parent request',
    save_and_add_to_knowledge: 'Save and Add to Knowledge',
    msi_msp: 'MSI/MSP',
    exe: 'EXE',
    script: 'Script',
    execution: 'Execution',
    mst: 'MST',
    argument: 'Argument | Arguments',
    packages: 'Package | Packages',
    shared_drive_file_path: 'Shared Drive File Path',
    path: 'Path',
    click_to_copy: 'Click to Copy',
    registry_template: 'Registry Template | Registry Templates',
    registry_item: 'Registry Item | Registry Items',
    registry: 'Registry',
    header: 'Header',
    data: 'Data',
    assigne: 'Assigne',
    dynamic: 'Dynamic',
    variable: 'Variable',
    display: 'Display',
    captcha: 'Captcha',
    download_to_file_server: 'Download to File Server',
    form_rule: 'Form Rule | Form Rules',
    show: 'Show',
    hide: 'Hide',
    mandate: 'Mandate',
    non_mendate: 'Non-Mandate',
    disable: 'Disable',
    skip_reboot_shutdown_ifnot_required:
      'Skip Reboot/Shutdown if not required.',
    allow_to_skip_deployment: 'Allow to Skip Deployment',
    force_deployment: 'Force Deployment After(Days)',
    initial_deployment_on: 'Initial Deployment On',
    shutdown: 'Shutdown',
    do_nothing: 'Do Nothing',
    message: 'Message | Messages',
    system_start_up: 'System Start Up',
    on_next_cycle: 'On Next Scan Cycle',
    next_scan_time: 'Next Scan Time',
    repo_sync_status: 'Repo Sync Status',
    redhat_repo_sync_cycle: 'Redhat Repo Sync Cycle',
    confirm_deploy_patch:
      'This will deploy only Approved Patches. Do you want to continue ?',
    reverse_action_instruction: 'Reverse Actions if conditions are not matched',
    configuration_tested_success: 'Configuration Successfully Done.',
    plugin_server: 'Plugin Server',
    file_not_found: 'File Not Found',
    compare: 'Compare',
    asset_movement: 'Asset Movement',
    movement: 'Movement | Movements',
    expected_return_date: 'Expected Return Date',
    return_date: 'Return Date',
    manage_baseline: 'Manage Baseline | Manage Baselines',
    baseline: 'Baseline | Baselines',
    attribute: 'Attribute | Attributes',
    variance: 'Variance',
    run_auto_assignment_process: 'Run Auto Assignment Process',
    initiated_successfully:
      'Auto Assignment Process has been initiated successfully',
    returnable_type: 'Returnable Type',
    returnable: 'Returnable',
    in_warranty: 'In Warranty',
    replaced: 'Replaced',
    sent_date: 'Sent Date',
    repair_type: 'Repair Type',
    repair: 'Repair',
    warranty_covered: 'Warranty Covered',
    replaced_by: 'Replaced By',
    send_to_repair: 'Send to Repair',
    return_from_repair: 'Return From Repair',
    to_return: 'To Return',
    dispatch: 'Dispatch',
    faulty: 'Faulty',
    out_movement_id: 'Out Movement Id',
    in_movement_id: 'In Movement Id',
    unit: 'Unit',
    dependent_value: 'Dependent Options',
    detection_date: 'Detection Date',
    excluded: 'Excluded',
    allow_to_login: 'Allow to Login',
    ai_bot: 'Virtual Agent',
    telegram_from_ticket: 'Telegram From Ticket',
    messaging_app: 'Messaging App',
    chat_flow: 'Chat Flow',
    chat_flow_variable: 'Chat Flow Variable',
    slack_app_config: 'Slack App Config',
    teams_app_config: 'Teams App Config',
    telegram_app_config: 'Telegram App Config',
    whatsapp_config: 'Whatsapp Config',
    list_of_examples: 'List of Examples',
    example: 'Example',
    static_choice: 'Static Choice',
    reference_choice: 'Reference Choice',
    transfer_chat: 'Transfer Chat',
    close_chat: 'Close Chat',
    ask_question: 'Ask Question',
    bot_response: 'Bot Response',
    utility: 'Utility',
    sign_in_with_google: 'Sign in with Google',
    re_order: 'Re-order',
    variable_name: 'Variable Name',
    acknowledge: 'Acknowledge',
    prompt: 'Prompt',
    date_input_type: 'Date Input Type',
    confirm_mesaage: 'Confirm Message',
    reference_choice_type: 'Reference Choice Type',
    payload: 'Payload',
    model: 'Model',
    success_next_action: 'Success Next Action',
    response_message: 'Response Message',
    failer_next_action: 'Failure Next Action',
    bandwidth_utilization: 'Bandwidth Utilization',
    relay_server_settings: 'Relay Server Settings',
    on_page_watching: 'is on this page | are on this page',
    no_data_msg: 'No Data Message',
    no_data_message: 'No Data Found',
    user_text: 'User Text',
    user_date: 'User Date',
    user_static_choice: 'User Static Choice',
    user_reference_choice: 'User Reference Choice',
    bot_text: 'Bot Text',
    bot_multiflow: 'Bot Multiflow',
    utility_action: 'Utility Action',
    plugin_action: 'Plugin Action',
    utility_chat_flow: 'Utility Chat Flow',
    utility_decision: 'Utility Decision',
    utility_search: 'Utility Search',
    utility_go_to: 'Utility Go to',
    auto_ticket_create: 'Incident Schedule',
    auto_task_create: 'Task Schedule',
    assigned_technician_group: 'Assigned Technician Group',
    assigned_technician: 'Assigned Technician',
    relevant: 'Relevant',
    ubuntu: 'Ubuntu',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    client_number: 'Client Number',
    tenant_id: 'Tenant ID',
    user_filter: 'User Filter',
    group_filter: 'Group Filter',
    rest_integration: 'Rest Integration',
    app_integration: 'App Integration',
    configuration_activity: 'Configuration Audit',
    operation_activity: 'Operation Audit',
    password_policy: 'Password Policy',
    user_sessions: 'User Sessions',
    database_backup: 'Database Backup',
    data_archiving: 'Data Archiving',
    product_license: 'Product License',
    log_download: 'Log Download',
    outgoing_email_server: 'Outgoing Email Servers',
    incoming_email_server: 'Incoming Email Server | Incoming Email Servers',
    email_preference: 'Email Preference',
    mobile_agent: 'Mobile Agents',
    discovery_service: 'Discovery Service',
    agent_build: 'Agent Build',
    agent_scan_history: 'Agent Scan History',
    update_patch_database: 'Update Patch Database',
    sso_provider: 'SSO Provider',
    onelogin: 'One Login',
    page_login: 'Login Page',
    page_forgot_password: 'Forgot Password Page',
    page_reset_password: 'Reset Password Page',
    page_otp: 'OTP Page',
    page_200: 'Success Page (Error code: 200)',
    page_500: 'Error Page (Error Code: 500)',
    page_404: 'Page Not Found (Error Code: 404)',
    page_register: 'Register Page',
    page_requester_reset_password: 'Requester Reset Password Page',
    selected: 'Selected',
    page_requester_self_register: 'Requester Self Registration Page',
    ola_config: 'OLA Configuration',
    accept: 'Accept',
    discard: 'Discard',
    you_have_pending_changes: 'You have pending changes',
    pending_changes_confiem: 'Are you sure, your changes will be lost?',
    stopped_successfully: '{resource} has been stopped successfully.',
    confirm_status: 'Are you sure, you want to {status} ?',
    resolve: 'Resolve',
    status_message_error: '{resource} can not be {status} because of error.',
    request_assigned_to_you: 'Request has been assigned to you',
    your_group_has_new_request_assigned: 'Your Group has new request assigned',
    status_updated_on_request_assigned_to_you:
      'Status has been updated on request assigned to you',
    new_request_created: 'New Request Created',
    comment_on_request_assigned_to_you: 'Comment on request assigned to you',
    your_preference_saved_success:
      'Your preference has been saved successfully.',
    your_preference_saved_failed:
      'Your preference has not been saved! try again…',
    authentication_needed: 'Authentication Needed',
  }
}
